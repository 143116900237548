import React, { Component } from 'react';
import getLine from '../../../helpers/getLine';
import FooterContent from './FooterContent';
import FooterLine from './FooterLine';
import './Footer.css';

class FooterContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      line: '#00B2EE',
    };

    this.getLineColor = this.getLineColor.bind(this);
  }

  componentDidMount = () => {
    const { location } = this.props;
    this.getLineColor(location.pathname);
  };

  componentWillReceiveProps = (nextProps) => {
    this.getLineColor(nextProps.location.pathname);
  };

  getLineColor(path) {
    const line = getLine(path);
    this.setState({
      line,
    });
  }

  render() {
    const { line } = this.state;

    return (
      <footer>
        <hr className="footerBorder" />
        <FooterContent />
        <FooterLine lineBg={line} />
      </footer>
    );
  }
}

export default FooterContainer;
