import React from 'react';
import { Spin } from 'antd';

import * as S from './styles';

function FullPageSpinner() {
  return (
    <S.Wrapper>
      <Spin />
    </S.Wrapper>
  );
}

export default FullPageSpinner;
