import React from 'react';
import { Row, Col, List } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import logoIfal from '../../../assets/img/footer/logo-ifal.png';
import logoCinfo from '../../../assets/img/footer/logo-cinfo.png';
import logo100anos from '../../../assets/img/lar-100-anos-small.png';

export default function FooterContent() {
  const { Item } = List;
  return (
    <Row type="flex" justify="center" align="middle">
      <Col
        xs={11}
        md={11}
        lg={{ span: 4, order: 2 }}
        xl={4}
        xxl={{ offset: 2 }}
        className="footerList"
      >
        <List size="small">
          <Item className="footerLink">
            <Link to="/atividades">Atividades</Link>
          </Item>
          <Item className="footerLink">
            <Link to="/auditorios">Auditórios</Link>
          </Item>
          <Item className="footerLink">
            <Link to="/doe">Doações</Link>
          </Item>
          <Item className="footerLink">
            <Link to="/sobre-o-lar">Institucional</Link>
          </Item>
          <Item className="footerLink">
            <Link to="/noticias">Notícias</Link>
          </Item>
        </List>
      </Col>
      <Col
        xs={11}
        md={11}
        lg={{ span: 4, order: 3 }}
        xl={4}
        className="footerList"
      >
        <List size="small">
          <Item className="footerLink">
            <Link to="/abrace-este-lar">Abrace Este Lar</Link>
          </Item>
          <Item className="footerLink">
            <Link to="/abrace-este-lar/ginasio">
              <FontAwesomeIcon icon={faAngleRight} className="footerIcon" />
              Ginásio
            </Link>
          </Item>
          <Item className="footerLink">
            <Link to="/abrace-este-lar/livraria">
              <FontAwesomeIcon icon={faAngleRight} className="footerIcon" />
              Livraria
            </Link>
          </Item>
          <Item className="footerLink">
            <Link to="/abrace-este-lar/bazar">
              <FontAwesomeIcon icon={faAngleRight} className="footerIcon" />
              Bazar
            </Link>
          </Item>
          <Item className="footerLink">
            <Link to="/abrace-este-lar/voluntarie-se">
              <FontAwesomeIcon icon={faAngleRight} className="footerIcon" />
              Voluntaria-se
            </Link>
          </Item>
        </List>
      </Col>
      <Col
        xs={23}
        md={11}
        lg={{ span: 6, order: 1 }}
        xxl={3}
        className="footerList"
      >
        <h2>Entre em Contato</h2>
        <List size="small">
          <Item>
            <FontAwesomeIcon
              icon={faPhone}
              rotation={90}
              className="footerIcon"
            />
            (82) 2121-1300
          </Item>
          <Item>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="footerIcon" />
            Avenida Gustavo Paiva, 4291 – Mangabeiras.
          </Item>
          <Item>
            <FontAwesomeIcon icon={faEnvelope} className="footerIcon" />
            <a href="mailto:lar@larsaodomingos.com.br" className="mailTo">
              lar@larsaodomingos.com.br
            </a>
          </Item>
        </List>
      </Col>
      <Col
        xs={23}
        md={{ span: 11, order: 4 }}
        lg={5}
        xxl={3}
        className="footerList"
      >
        <h2>Parceiros</h2>
        <List size="small">
          <Item className="partners">
            <img src={logoCinfo} alt="CINFO" />
          </Item>
          <Item className="partners">
            <img
              src={logoIfal}
              alt="Instituto Federal de Alagoas - Campus Maceió"
            />
          </Item>
        </List>
      </Col>
      <Col
        xs={23}
        md={{ span: 10, order: 4 }}
        lg={3}
        xxl={3}
        className="footerList"
      >
        <img src={logo100anos} alt="100 anos" className="larImg" />
      </Col>
    </Row>
  );
}
