import React, { Component } from 'react';
import getLine from '../../../helpers/getLine';
import TopBarMenu from './TopBarMenu';

class TopBarMenuContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalLoginIsOpen: false,
      userIsLoggedIn: false,
      userName: '',
    };

    this.getBackgroundColor = this.getBackgroundColor.bind(this);
    this.openModalLogin = this.openModalLogin.bind(this);
    this.closeModalLogin = this.closeModalLogin.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount = () => {
    const { location } = this.props;
    /* Pegar a rota e alterar a cor do bg */
    this.getBackgroundColor(location.pathname);

    /* Checar se usuário está logado */
    const userInfo = sessionStorage.getItem('@LarSaoDomingos:token');
    const userName = sessionStorage.getItem('@LarSaoDomingos:username');
    let userIsLoggedIn = false;
    if (userInfo !== null) {
      userIsLoggedIn = true;
    }

    this.setState({
      userIsLoggedIn,
      userName,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    /* Pegar a rota e alterar a cor do bg */
    this.getBackgroundColor(nextProps.location.pathname);

    /* Checar se usuário está logado */
    const userInfo = sessionStorage.getItem('@LarSaoDomingos:token');
    const userName = sessionStorage.getItem('@LarSaoDomingos:username');
    let userIsLoggedIn = false;
    if (userInfo !== null) {
      userIsLoggedIn = true;
    }

    this.setState({
      modalLoginIsOpen: false,
      userIsLoggedIn,
      userName,
    });
  };

  getBackgroundColor(path) {
    const bgLine = getLine(path);
    this.setState({
      bgLine,
    });
  }

  openModalLogin() {
    this.setState({ modalLoginIsOpen: true });
  }

  closeModalLogin() {
    this.setState({ modalLoginIsOpen: false });
  }

  logout() {
    sessionStorage.removeItem('@LarSaoDomingos:token');
    sessionStorage.removeItem('@LarSaoDomingos:username');
    this.setState({ userIsLoggedIn: false });
  }

  render() {
    const { bgLine, userIsLoggedIn, modalLoginIsOpen, userName } = this.state;
    return (
      <TopBarMenu
        bgLine={bgLine}
        chooseALoginLink={() => this.openModalLogin()}
        userIsLoggedIn={userIsLoggedIn}
        modalLoginIsOpen={modalLoginIsOpen}
        closeModalLogin={() => this.closeModalLogin()}
        userName={userName}
        logout={() => this.logout()}
      />
    );
  }
}

export default TopBarMenuContainer;
