import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { django } from '../../../services/api';
import LoginForm from './LoginForm';

class LoginFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalLoginIsOpen: props.modalLoginIsOpen,
      loading: false,
      componentToGo: null,
      errorMsg: '',
      msgButtonFlag: false, // Mudar p/ true após adicionar recaptcha
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      modalLoginIsOpen: nextProps.modalLoginIsOpen,
      loading: false,
      componentToGo: null,
      errorMsg: '',
      msgButtonFlag: true,
    });
  };

  /* Método para fazer login com usuário e senha */
  login = (data) => {
    if (data !== undefined) {
      new Promise((resolve) => {
        resolve(this.setState({ loading: true }));
      }).then(() => {
        const loginData = {
          username: data.email,
          email: data.email,
          password: data.pass,
        };

        django
          .post('auth/login/', loginData)
          .then(async (res) => {
            const data = { token: res.data.key };
            const { key } = res.data;

            try {
              const donator = await django.post('/auth/doador', data);

              const name = donator.data.data.usuario.first_name;
              sessionStorage.setItem('@LarSaoDomingos:token', key);
              sessionStorage.setItem('@LarSaoDomingos:username', name);

              this.goToComponent('doe');
            } catch (err) {
              this.setState({
                loading: false,
                errorMsg: 'Erro ao realizar login. Tente novamente.',
              });
            }
          })
          .catch(() => {
            this.setState({
              loading: false,
              errorMsg: 'Erro ao realizar login. Tente novamente.',
            });
          });
      });
    }
  };

  /* Método para abrir o modal de signup */
  openModalSignup = () => {
    this.setState({ modalSignupIsOpen: true });
  };

  /* Método para fechar o modal de signup */
  closeModalSignup = () => {
    this.setState({ modalSignupIsOpen: false });
  };

  /* Método para recuperar a senha */
  forgotPass = () => {};

  // Método para desabilitar o botão após clicar
  statusButton = () => {
    this.setState({
      msgButtonFlag: true,
    });
  };

  /* Método para redirecionar */
  goToComponent = (router) => {
    this.setState({ componentToGo: router });
  };

  render() {
    const {
      componentToGo,
      modalLoginIsOpen,
      modalSignupIsOpen,
      loading,
      errorMsg,
      msgButtonFlag,
    } = this.state;
    /* Go to page */
    if (componentToGo !== null) {
      return <Redirect to={`/${componentToGo}`} />;
    }

    return (
      <LoginForm
        modalLoginIsOpen={modalLoginIsOpen}
        loading={loading}
        login={(e) => this.login(e)}
        errorMsg={errorMsg}
        modalSignupIsOpen={modalSignupIsOpen}
        closeModalSignup={() => this.closeModalSignup()}
        signup={() => this.openModalSignup()}
        forgotPass={() => this.forgotPass()}
        verifyCallbackRecaptcha={(e) => this.verifyCallbackRecaptcha(e)}
        callbackRecaptcha={() => this.callbackRecaptcha()}
        msgButtonFlag={msgButtonFlag}
        statusButton={() => this.statusButton()}
      />
    );
  }
}

export default LoginFormContainer;
