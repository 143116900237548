import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab);

export default function FooterLine({ lineBg }) {
  const currentYear = new Date().getFullYear();

  const styles = {
    back: {
      color: 'white',
      fontSize: '2em',
      padding: '4px',
    },
  };

  return (
    <div style={{ background: lineBg }} className="footerLine">
      <Row type="flex" justify="center" style={{ padding: '4px' }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/larsaodomingosmaceio/"
        >
          <FontAwesomeIcon icon={['fab', 'facebook']} style={styles.back} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/larsaodomingos/"
        >
          <FontAwesomeIcon icon={['fab', 'instagram']} style={styles.back} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/larsaodomingos"
        >
          <FontAwesomeIcon icon={['fab', 'twitter']} style={styles.back} />
        </a>
      </Row>

      <Row type="flex" justify="center">
        <Col style={{ color: 'white' }}>
          Lar São Domingos {currentYear} &copy; Todos os Direitos Reservados
        </Col>
      </Row>
    </div>
  );
}

FooterLine.propTypes = {
  lineBg: PropTypes.string,
};

FooterLine.defaultProps = {
  lineBg: '#00B2EE',
};
