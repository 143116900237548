import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, html {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: Poppins, sans-serif;
  }

  @media only screen and (max-width: 768px) {
    input {
        font-size: 16px;
    }
}
`;

export default GlobalStyle;
