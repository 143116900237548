import React, { Fragment } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFutbol,
  faBookReader,
  faShoppingBasket,
  faHands,
} from '@fortawesome/free-solid-svg-icons';
import GlobalStyle from './styles/global';
import Routes from './routes';
import './assets/css/normalize.css';

library.add(faFutbol, faBookReader, faShoppingBasket, faHands);

const App = () => (
  <Fragment>
    <GlobalStyle />
    <Routes />
  </Fragment>
);

export default App;
