import React, { Component, Fragment } from 'react';
import { Form, Input, Button, Modal, Icon, Row, Col } from 'antd';
import SignupFormContainer from '../signup-form/SignupFormContainer';
import loginImage from '../../../assets/img/lar-100-anos-small.png';
import iconLar from '../../../assets/img/logo-vertical.png';
import './LoginForm.css';

class LoginForm extends Component {
  componentWillReceiveProps = (nextProps) => {
    const { form } = this.props;
    /* Resetar formulário */
    if (!nextProps.modalLoginIsOpen) {
      form.resetFields();
    }
  };

  login = (e) => {
    const { form, login } = this.props;
    e.preventDefault();
    // this.props.statusButton();
    form.validateFields((err, values) => {
      if (!err) {
        login(values);
      }
    });
  };

  render() {
    const {
      form,
      login,
      loading,
      errorMsg,
      modalSignupIsOpen,
      closeModalSignup,
      signup,
      forgotPass,
    } = this.props;

    const { Item } = Form;

    return (
      <Fragment>
        <Row type="flex" justify="center">
          <div className="LoginImage">
            <img src={loginImage} alt="100 anos de amor e dedicação" />
          </div>
        </Row>
        <Form onSubmit={this.login} className="LoginForm">
          <Item hasFeedback className="FormInput">
            {form.getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'Este e-mail não é valido',
                },
                {
                  required: true,
                  message: 'Campo de preenchimento obrigatório',
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Email"
              />,
            )}
          </Item>

          <Item hasFeedback className="FormInput">
            {form.getFieldDecorator('pass', {
              rules: [
                {
                  required: true,
                  message: 'Campo de preenchimento obrigatório',
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="Senha"
              />,
            )}
          </Item>

          <Button
            type="primary"
            loading={loading}
            htmlType="submit"
            onClick={() => login()}
            className="FormButton"
          >
            Enviar
          </Button>
        </Form>
        <p className="LoginFormErroMsg">{errorMsg}</p>

        <Row>
          <Col
            xs={24}
            md={14}
            xl={14}
            xxl={14}
            type="flex"
            justify="center"
            align="middle"
            className="ContentColumn"
          >
            {
              // eslint-disable-next-line
      				<span>Ainda não possui cadastro? <a href='javascript:void(0)' className='LoginFormSignupLink' onClick={() => signup()}>Clique aqui</a></span>
            }
          </Col>
          <Col
            xs={24}
            md={10}
            xl={10}
            xxl={10}
            type="flex"
            justify="center"
            align="middle"
            className="ContentColumn"
          >
            {
              // eslint-disable-next-line
      				<span><a href='javascript:void(0)' onClick={() => forgotPass()}>Esqueci a senha</a></span>
            }
          </Col>
        </Row>

        <Modal
          title={
            <Fragment>
              <img
                src={iconLar}
                alt="Logomarca do Lar São Domingos"
                className="iconLogo"
              />
              Cadastre-se
            </Fragment>
          }
          visible={modalSignupIsOpen}
          onCancel={closeModalSignup}
          footer={[]}
        >
          <SignupFormContainer modalSignupIsOpen={modalSignupIsOpen} />
        </Modal>
      </Fragment>
    );
  }
}

export default Form.create()(LoginForm);
