import axios from 'axios';

const onDjangoAuth = async (endpoint, method, data = false) => {
  const URL = process.env.REACT_APP_DJANGO_API + endpoint;
  const response = await axios(URL, {
    method,
    headers: {
      Authorization: `Token ${process.env.REACT_APP_TOKEN}`,
    },
    data,
  });
  return response.data;
};

export default onDjangoAuth;
