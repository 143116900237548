import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Icon } from 'antd';
import Recaptcha from 'react-recaptcha';
import './SignupForm.css';

class SignupForm extends Component {
  componentWillReceiveProps = (nextProps) => {
    const { form } = this.props;
    /* Resetar formulário */
    if (!nextProps.modalSignupIsOpen) {
      form.resetFields();
    }
  };

  signup = (e) => {
    const { form, signup } = this.props;

    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        signup(values);
      }
    });
  };

  render() {
    const { form, verifyCallbackRecaptcha, msgButtonFlag, errorMsg, loading } =
      this.props;

    const { Item } = Form;

    return (
      <div>
        <Form onSubmit={this.signup} className="SignupForm">
          <Row gutter={16}>
            <Col xs={12} md={12} xl={12} xxl={12}>
              <Item hasFeedback>
                {form.getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Campo de preenchimento obrigatório',
                    },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    placeholder="Nome"
                  />,
                )}
              </Item>
            </Col>
            <Col xs={12} md={12} xl={12} xxl={12}>
              <Item hasFeedback>
                {form.getFieldDecorator('lastname', {
                  rules: [
                    {
                      required: true,
                      message: 'Campo de preenchimento obrigatório',
                    },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    placeholder="Sobrenome"
                  />,
                )}
              </Item>
            </Col>
          </Row>

          <Item hasFeedback>
            {form.getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'Este e-mail não é valido',
                },
                {
                  required: true,
                  message: 'Campo de preenchimento obrigatório',
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Email"
                type="email"
              />,
            )}
          </Item>

          <Item hasFeedback>
            {form.getFieldDecorator('cpf', {
              rules: [
                {
                  required: true,
                  message: 'Campo de preenchimento obrigatório',
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="CPF"
              />,
            )}
          </Item>

          <Item hasFeedback>
            {form.getFieldDecorator('pass', {
              rules: [
                {
                  required: true,
                  message: 'Campo de preenchimento obrigatório',
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Senha"
                type="password"
              />,
            )}
          </Item>

          <Item hasFeedback>
            {form.getFieldDecorator('repeatPass', {
              rules: [
                {
                  required: true,
                  message: 'Campo de preenchimento obrigatório',
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="retweet" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Repita a senha"
                type="password"
              />,
            )}
          </Item>

          <Item>
            <Recaptcha
              ref={(e) => {
                this.captcha = e;
              }}
              sitekey="6LeNVWcUAAAAAKiqYAK8KGcuQXgd2iAGOpnCJMS2"
              render="explicit"
              hl="pt-BR"
              verifyCallback={verifyCallbackRecaptcha}
              onloadCallback={this.onLoadRecaptcha}
            />
          </Item>

          <Button
            disabled={msgButtonFlag}
            type="primary"
            loading={loading}
            htmlType="submit"
            style={{ width: '100%' }}
          >
            Cadastrar
          </Button>
        </Form>

        <p className="SignupFormErroMsg">{errorMsg}</p>
      </div>
    );
  }
}

export default Form.create()(SignupForm);
