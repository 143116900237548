const lines = [
  {
    url: [''],
    line: '#00B2EE',
  },
  {
    url: ['sobre-o-lar'],
    line: '#F2994A',
  },
  {
    url: ['noticias', 'auditorios', 'atividades', 'abrace-este-lar'],
    line: '#E95757',
  },
  {
    url: ['login'],
    line: '#828282',
  },
  {
    url: ['doacoes', 'doe'],
    line: '#009241',
  },
];

function basename(path) {
  return path.split('/')[1];
}

const getLine = (path) => {
  let theLine;

  lines.forEach((item) => {
    const { url, line } = item;
    if (url.includes(basename(path))) {
      theLine = line;
    }
  });

  return theLine;
};

export default getLine;
