import React from 'react';
import { Modal, Menu, Dropdown } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import LoginFormContainer from '../login-form/LoginFormContainer';
import logo from '../../../assets/img/logo.png';
import iconLar from '../../../assets/img/logo-vertical.png';
import './TopBarMenu.css';

const TopBarMenu = ({ bgLine, modalLoginIsOpen, closeModalLogin }) => {
  const menuForSmallDevices = (
    <Menu
      id="topBarSmallDevices"
      style={{ width: '300px', marginRight: '5px', textAlign: 'center' }}
    >
      <Menu.Item key="0" style={{ height: '60px', lineHeight: '50px' }}>
        <NavLink exact to="/" activeStyle={{ backgroundColor: bgLine }}>
          Início
        </NavLink>
      </Menu.Item>

      <Menu.Item key="1" style={{ height: '60px', lineHeight: '50px' }}>
        <NavLink to="/sobre-o-lar" activeStyle={{ backgroundColor: bgLine }}>
          Institucional
        </NavLink>
      </Menu.Item>

      <Menu.Item key="2" style={{ height: '60px', lineHeight: '50px' }}>
        <NavLink
          to="/abrace-este-lar"
          activeStyle={{ backgroundColor: bgLine }}
        >
          Abrace este Lar
        </NavLink>
      </Menu.Item>

      <Menu.Item key="3" style={{ height: '60px', lineHeight: '50px' }}>
        <NavLink to="/doacoes" activeStyle={{ backgroundColor: bgLine }}>
          Doações
        </NavLink>
      </Menu.Item>

      <Menu.Item key="7" style={{ height: '60px', lineHeight: '50px' }}>
        <NavLink to="/auditorios">Auditórios</NavLink>
      </Menu.Item>

      {/* <Menu.Item key="8" style={{ height: '60px', lineHeight: '50px' }}>
        <span onClick={chooseALoginLink}>Entrar</span>
      </Menu.Item> */}
    </Menu>
  );

  //   const menuDropDown = (
  //     <Menu>
  //       <Menu.Item key="1">
  //         <Icon type="user" />
  //         Meus dados
  //       </Menu.Item>
  //       <Menu.Item key="2" onClick={logout}>
  //         <Icon type="poweroff" />
  //         Sair
  //       </Menu.Item>
  //     </Menu>
  //   );

  return (
    <header>
      <div className="topBarMenuBody">
        <nav className="topBarMenuContainer">
          <div className="topBarMenuLogo">
            <Link to="/">
              <img
                src={logo}
                alt="Logomarca do Lar São Domingos"
                style={{ position: 'relative' }}
              />
            </Link>
          </div>

          <div className="topBarMenuSmallScreen">
            <Dropdown overlay={menuForSmallDevices} trigger={['click']}>
              <div className="topBarMenuLink">
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ fontSize: '20px', verticalAlign: 'middle' }}
                />
              </div>
            </Dropdown>
          </div>

          <div className="topBarMenuLargeScreen">
            {/* {userIsLoggedIn ? (
              <div className="topBarMenuItem">
                <Dropdown overlay={menuDropDown} trigger={['click']}>
                  <span className="topBarMenuLink" style={{ color: bgLine }}>
                    Bem vindo, {userName} <Icon type="down" />
                  </span>
                </Dropdown>
              </div>
            ) : (
              <div className="topBarMenuItem">
                <span
                  onClick={chooseALoginLink}
                  className="topBarMenuLink"
                  style={{ color: bgLine }}
                >
                  Entrar
                </span>
              </div>
            )} */}

            <div className="topBarMenuItem">
              <NavLink
                to="/auditorios"
                className="topBarMenuLink"
                activeClassName="topBarAuditoriums"
              >
                Auditórios
              </NavLink>
            </div>

            <div className="topBarMenuItem">
              <NavLink
                to="/doacoes"
                className="topBarMenuLink"
                activeClassName="topBarDonations"
              >
                Doações
              </NavLink>
            </div>

            <div>
              <NavLink
                to="/abrace-este-lar"
                className="topBarMenuLink"
                activeClassName="topBarBePartOfIt"
              >
                Abrace este Lar
              </NavLink>
            </div>

            <div className="topBarMenuItem">
              <NavLink
                to="/sobre-o-lar"
                className="topBarMenuLink"
                activeClassName="topBarAbout"
              >
                Institucional
              </NavLink>
            </div>

            <div className="topBarMenuItem">
              <NavLink
                exact
                to="/"
                className="topBarMenuLink"
                activeClassName="topBarHome"
              >
                Início
              </NavLink>
            </div>
          </div>
        </nav>

        <div className="topBarMenuColorLine" style={{ background: bgLine }} />

        <Modal
          title={
            <React.Fragment>
              <img
                src={iconLar}
                alt="Logomarca do Lar São Domingos"
                className="iconLogo"
              />
              Área do Doador
            </React.Fragment>
          }
          visible={modalLoginIsOpen}
          onCancel={closeModalLogin}
          footer={[]}
          width={500}
        >
          <LoginFormContainer modalLoginIsOpen={modalLoginIsOpen} />
        </Modal>
      </div>
      <div style={{ height: '50px' }} />
    </header>
  );
};

export default TopBarMenu;
