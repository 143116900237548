import styled from 'styled-components';

export const Wrapper = styled.div`
  bottom: 0;
  height: 50px;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 50px;
  z-index: 1070;
`;
