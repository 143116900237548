import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import onDjangoAuth from '../../../services/authenticationApi';
import SignupForm from './SignupForm';

class SignupFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalSignupIsOpen: props.modalSignupIsOpen,
      loading: false,
      errorMsg: '',
      componentToGo: null,
      msgButtonFlag: true,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      modalSignupIsOpen: nextProps.modalSignupIsOpen,
      loading: false,
      errorMsg: '',
      componentToGo: null,
    });
  };

  /* Método para fechar o modal de Signup */
  closeModalSignup = () => {
    this.setState({ modalSignupIsOpen: false });
  };

  /* Método para fazer Signup */
  signup = (data) => {
    new Promise((resolve) => {
      resolve(this.setState({ loading: true }));
    }).then(() => {
      if (data.pass !== data.repeatPass) {
        this.setState({
          loading: false,
          errorMsg: 'Confira se as senhas digitadas são identicas',
        });
      } else {
        const signupData = {
          first_name: data.name,
          last_name: data.lastname,
          username: data.email,
          password: data.pass,
          email: data.email,
          groups: [],
        };
        onDjangoAuth('/usuarios/', 'POST', signupData)
          .then(() => {
            return onDjangoAuth('/usuarios/', 'GET');
          })
          .then((res) => {
            const doadorData = {
              usuario: res[0].url,
              cpf: data.cpf,
            };
            return onDjangoAuth('/doadores/', 'POST', doadorData);
          })
          .then(() => {
            const loginData = {
              username: data.email,
              email: data.email,
              password: data.pass,
            };
            onDjangoAuth('/auth/login/', 'POST', loginData).then((childRes) => {
              const data = { token: childRes.key };
              const { key } = childRes;
              return onDjangoAuth('/auth/doador', 'POST', data)
                .then((res) => {
                  const name = res.data.usuario.first_name;
                  sessionStorage.setItem('@LarSaoDomingos:token', key);
                  sessionStorage.setItem('@LarSaoDomingos:username', name);
                  this.goToComponent('doacoes');
                  this.setState({
                    modalSignupIsOpen: false,
                  });
                })
                .catch(() => {
                  this.setState({
                    loading: false,
                    errorMsg: 'Erro ao realizar login. Tente novamente.',
                  });
                });
            });
          })
          .catch(() => {
            this.setState({
              loading: false,
              errorMsg:
                'Erro ao cadastrar usuário. Verifique se você já possui cadastro.',
            });
          });
      }
    });
  };

  /* Método para verificar o Recaptcha */
  verifyCallbackRecaptcha = (e) => {
    if (e) {
      this.setState({ msgButtonFlag: false });
    }
  };

  callbackRecaptcha = () => {};

  /* Método para redirecionar */
  goToComponent = (router) => {
    this.setState({ componentToGo: router });
  };

  render() {
    const {
      componentToGo,
      modalSignupIsOpen,
      loading,
      errorMsg,
      msgButtonFlag,
    } = this.state;

    if (componentToGo !== null) {
      return <Redirect to={`/${componentToGo}`} />;
    }

    return (
      <SignupForm
        modalSignupIsOpen={modalSignupIsOpen}
        loading={loading}
        closeModalSignup={() => this.closeModalSignup()}
        signup={(e) => this.signup(e)}
        errorMsg={errorMsg}
        msgButtonFlag={msgButtonFlag}
        verifyCallbackRecaptcha={(e) => this.verifyCallbackRecaptcha(e)}
        callbackRecaptcha={() => this.callbackRecaptcha()}
      />
    );
  }
}

export default SignupFormContainer;
