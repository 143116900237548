import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { isAuthenticated } from './services/auth';

import Footer from './components/shared/footer/FooterContainer';
import FullPageSpinner from './components/FullPageSpinner';
import TopBarMenu from './components/shared/top-bar-menu/TopBarMenuContainer';

const About = lazy(() => import('./components/About/About'));
const Activities = lazy(() => import('./components/Activities/Activities'));
const BePartOfIt = lazy(() => import('./components/be-part-of-it/BePartOfIt'));
const Auditoriums = lazy(() =>
  import('./components/auditoriums/AuditoriumsContainer'),
);
const Donations = lazy(() =>
  import('./components/donations/DonationsContainer'),
);
const News = lazy(() => import('./components/news/NewsContainer'));
const Places = lazy(() => import('./components/places/Places'));
const SpecificNews = lazy(() =>
  import('./components/specific-news/SpecificNewsContainer'),
);
const Home = lazy(() => import('./components/Home'));
const User = lazy(() => import('./components/user/UserContainer'));

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Suspense fallback={<FullPageSpinner />}>
      <Route path="/" component={TopBarMenu} />

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/noticias" component={News} />
        <Route path="/noticias/:id" component={SpecificNews} />
        <Route path="/atividades" component={Activities} />
        <Route exact path="/abrace-este-lar" component={BePartOfIt} />
        <Route path="/abrace-este-lar/:url" component={Places} />
        <Route path="/auditorios" component={Auditoriums} />
        <Route path="/doacoes" component={Donations} />
        <Route path="/sobre-o-lar" component={About} />

        {/* <Route path="/doe" component={DonationsSystem} /> */}
        {/* <Route path="/doe/boleto" component={DonationsBoletoForm} /> */}
        <PrivateRoute path="/user" component={User} />

        <Redirect to="/" />
      </Switch>

      <Route path="/" component={Footer} />
    </Suspense>
  </BrowserRouter>
);

export default Routes;
