import axios from 'axios';

const django = axios.create({
  baseURL: process.env.REACT_APP_DJANGO_API,
  headers: { Authorization: `Token ${process.env.REACT_APP_TOKEN}` },
});

const wp = axios.create({
  baseURL: process.env.REACT_APP_WP_API,
});

export { django, wp };
